// ** Types Import
import { Settings } from 'src/@core/context/settingsContext'
import { NavLink, NavSectionTitle, VerticalNavItemsType } from 'src/@core/layouts/types'

// ** Custom Menu Components
import VerticalNavLink from './VerticalNavLink'
import VerticalNavSectionTitle from './VerticalNavSectionTitle'
import { useUser } from 'src/lib/contexts/UserContext'
import { useRef } from 'react'

interface Props {
  settings: Settings
  navVisible?: boolean
  groupActive: string[]
  currentActiveGroup: string[]
  verticalNavItems?: VerticalNavItemsType
  saveSettings: (values: Settings) => void
  setGroupActive: (value: string[]) => void
  setCurrentActiveGroup: (item: string[]) => void
}

const resolveNavItemComponent = (item: NavLink | NavSectionTitle) => {
  if ((item as NavSectionTitle).sectionTitle) return VerticalNavSectionTitle

  return VerticalNavLink
}

const VerticalNavItems = (props: Props) => {
  // ** Props
  const { verticalNavItems } = props
  const { user } = useUser()
  const refs = useRef({ checkout: false, cart: true, sso: true })

  const checkoutNavItems = verticalNavItems?.filter(
    (item: NavLink | NavSectionTitle) =>
      !(item as NavLink).path?.includes('cart') && !(item as NavLink).path?.includes('sso')
  )

  const cartNavItems = verticalNavItems?.filter((item: NavLink | NavSectionTitle) =>
    (item as NavLink).path?.includes('cart')
  )

  const ssoNavItems = verticalNavItems?.filter((item: NavLink | NavSectionTitle) =>
    (item as NavLink).path?.includes('sso')
  )

  const RenderCheckoutMenuItems = checkoutNavItems?.map((item: NavLink | NavSectionTitle, index: number) => {
    const hasPermissionToTab = user?.permissions?.some(
      (permission: any) => permission.id === (item as NavLink).rbacPermission
    )

    if (hasPermissionToTab) {
      refs.current.checkout = true
    }

    const TagName: any = resolveNavItemComponent(item)

    return (
      Boolean(hasPermissionToTab) && (
        <div key={index} className={`${(item as any)?.path}`}>
          <TagName {...props} item={item} />
        </div>
      )
    )
  })

  const RenderCartMenuItems = cartNavItems?.map((item: NavLink | NavSectionTitle, index: number) => {
    const hasPermissionToTab = user?.permissions?.some(
      (permission: any) => permission.id === (item as NavLink).rbacPermission
    )

    refs.current.cart = hasPermissionToTab ?? false

    const TagName: any = resolveNavItemComponent(item)
    return (
      <>
        {Boolean(hasPermissionToTab) && (
          <div key={index} className={`${(item as any)?.path}`}>
            <TagName {...props} item={item} />
          </div>
        )}
      </>
    )
  })

  const RenderSsoMenuItems = ssoNavItems?.map((item: NavLink | NavSectionTitle, index: number) => {
    const hasPermissionToTab = user?.permissions?.some(
      (permission: any) => permission.id === (item as NavLink).rbacPermission
    )

    refs.current.sso = hasPermissionToTab ?? false

    const TagName: any = resolveNavItemComponent(item)
    return (
      <div key={index} className={`${(item as any)?.path}`}>
        <TagName {...props} item={item} />
      </div>
    )
  })

  return (
    <div className='flex flex-col gap-3'>
      {refs.current.checkout && <VerticalNavSubHeader title='CHECKOUT'>{RenderCheckoutMenuItems}</VerticalNavSubHeader>}
      {refs.current.cart && <VerticalNavSubHeader title='CART'>{RenderCartMenuItems}</VerticalNavSubHeader>}
      {refs.current.sso && <VerticalNavSubHeader title='SSO'>{RenderSsoMenuItems}</VerticalNavSubHeader>}
    </div>
  )
}

interface VerticalNavSubHeaderProps {
  title: string
  children: React.ReactNode
}

const VerticalNavSubHeader = ({ title, children }: VerticalNavSubHeaderProps) => {
  return (
    <div className='flex flex-col gap-2'>
      <h2 className='text-base align-left font-semibold pl-[50px] py-2 pr-6'>{title}</h2>
      <div className='flex flex-col '>{children}</div>
    </div>
  )
}

export default VerticalNavItems
