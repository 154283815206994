// ** React Import
import { ReactNode } from 'react'

// ** Next Import
import Link from 'next/link'

// ** MUI Imports
import Box, { BoxProps } from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Typography, { TypographyProps } from '@mui/material/Typography'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import { useUser } from 'src/lib/contexts/UserContext'
import { X } from 'react-feather'
import StoreSwitcher from 'src/layouts/StoreSwitcher'

interface Props {
  hidden: boolean
  settings: Settings
  toggleNavVisibility: () => void
  saveSettings: (values: Settings) => void
  verticalNavMenuBranding?: (props?: any) => ReactNode
}

// ** Styled Components
const MenuHeaderWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(4.5),
  transition: 'padding .25s ease-in-out',
  minHeight: theme.mixins.toolbar.minHeight
}))

const HeaderTitle = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  lineHeight: 'normal',

  // textTransform: 'uppercase',
  color: theme.palette.text.primary,
  transition: 'opacity .25s ease-in-out, margin .25s ease-in-out'
}))

const StyledLink = styled('a')({
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none'
})

const VerticalNavHeader = (props: Props) => {
  // ** Props
  const { verticalNavMenuBranding: userVerticalNavMenuBranding, hidden } = props
  const minHeight = hidden ? '100px' : '64px'
  return (
    <MenuHeaderWrapper
      className={`flex flex-col gap-2 px-1 py-4 nav-header bg-gray-lightest min-h-28`}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight }}
    >
      {userVerticalNavMenuBranding ? (
        userVerticalNavMenuBranding(props)
      ) : hidden ? (
        <>
          <Link href='/overview' passHref>
            <StyledLink>
              <img height='24' src='/images/shopflo-logo.svg' alt='shopflo-logo' />
            </StyledLink>
          </Link>
          <StoreSwitcher isDark={true} />
        </>
      ) : (
        <></>
      )}
    </MenuHeaderWrapper>
  )
}
export default VerticalNavHeader
