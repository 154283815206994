import { BriefcaseClockOutline } from 'mdi-material-ui'
import { useMerchant } from '../contexts/MerchantContext'
import {
  ApplicationType,
  CatalogueCategoryType,
  CatalogueDataType,
  CatalogueNatureType,
  CoverageType,
  CustomerEligibilityType,
  DiscountFormStateType,
  GenerationType,
  PrerequisiteType,
  StackabilityConfigType
} from '../types/discounts'
import { LocalDeliveryGroupChargesType } from '../types/localDeliveryGroups'
import { DashboardAnnouncementsType, MerchantAttributesType, UpSellConfigType } from '../types/merchantAttributes'
import { discountConstants } from './constants'
import { isContentProtected, isUndefinedOrNullOrNaN, getActiveChannelsFromList } from './helpers'
import { parsePaymentModeConcessions } from 'src/lib/utils/discountHelpers'
import { OnboardingContextDataType } from '../types/onboarding'
import { ChannelDetailsType } from '../types/settings'
import { getCollectionById, getProductById, getItemByIds, getVariantsByIds } from '../core/api-methods'
import { catalogueAdapter } from 'src/lib/utils/adapters'
import { BillingDetailsPayloadType } from '../types/address'
import { ChannelConfigType } from 'src/lib/types/channels'
import {
  IntegrationsType,
  WebhookType,
  GoogleAdsTagsType,
  FacebookCombinedAnalyticsPixelType,
  FacebookCombinedAnalyticsType,
  LimechatType
} from 'src/lib/types/integrations'
import { UpSellFormStateType } from '../types/upsell'
import { rewardsAnalyticsWidgetsConfig } from 'src/assets/RewardsConfig'
import { RewardAnalyticsWidgetType } from '../types/rewards'
import { AnalyticsMetricType } from 'src/lib/types/analytics'
import { defaultTrustBadgesConfig } from 'src/assets/CustomizeUIConfig'
import { CART_WIDTH_FOR_MOBILE } from '../types/cartUI'
import { SegmentFormStateType } from '../types/segment'
import { parsePopupFromBackend, SSOMarketingPopup, SSOMarketingPopupBackendData } from '@shopflo/ui'
import { defaultSSOConfig } from './SSOHelpers'

/***********************************************************/
//                 Merchant Atrributes Parsing Logics
/***********************************************************/

export const parseMerchantData = (data: any) => {
  const getMerchantAgreementConfig = (agreement: any) => {
    const { has_accepted_terms, agreement_fields } = agreement || {}
    const agreementConfig = {
      hasAcceptedTerms: has_accepted_terms,
      agreementFields: {
        entityName: agreement_fields?.entity_name,
        pocName: agreement_fields?.poc_name,
        natureOfEntity: agreement_fields?.nature_of_entity,
        address: agreement_fields?.address,
        designation: agreement_fields?.designation,
        email: agreement_fields?.email,
        gstNumber: agreement_fields?.gst_number
      }
    }
    return agreementConfig
  }
  const getAnnouncementConfig = (announcements: any) => {
    const announcementsConfig: DashboardAnnouncementsType = {
      isEnabled: announcements?.enabled ?? false,
      message: announcements?.message ?? '',
      exploreLink: announcements?.explore_link ?? ''
    }
    return announcementsConfig
  }

  const parseUpSellConfig = (upsell: any) => {
    const upSellConfig: UpSellConfigType = {
      isEnabled: upsell?.is_enabled,
      upSellTitle: upsell?.upsell_title,
      discountedUpSellTitle: upsell?.discounted_upsell_title
    }
    return upSellConfig
  }

  const parseBannerConfig = (data: any) => {
    return data?.map((config: any) => {
      return {
        enabled: config?.enabled,
        color: config?.background_color,
        text: config?.html_text_string,
        type: config?.type,
        textColor: config?.text_color
      }
    })
  }

  const parseCouponVisibilityConfig = (data: any) => {
    return data?.map((config: any) => {
      return {
        areCouponsVisible: config?.visibile,
        type: config?.type,
        visibilityExtent: config?.visibility_extent
      }
    })
  }

  const parsePrimaryCTAConfigPayload = (data: any) => {
    return data?.map((config: any) => {
      return {
        type: config?.type,
        CTAText: config?.cta_text
      }
    })
  }

  const parseCartUIConfig = (data: any) => {
    return data?.map((config: any) => {
      return {
        sfContext: config?.sf_context ?? '',
        uiAttributes: {
          bannerConfig: parseBannerConfig(config?.ui_attributes?.banner_config) ?? [],
          bottomBannerConfig: parseBannerConfig(config?.ui_attributes?.bottom_banner_config) ?? [],
          colorPalette: {
            primaryColor: config?.ui_attributes?.color_pallet?.primary_color ?? '#000000',
            primaryTextColor: config?.ui_attributes?.color_pallet?.primary_text_color ?? '#FFFFFF'
          },
          primaryCTAConfig: parsePrimaryCTAConfigPayload(config?.ui_attributes.primary_cta_config) ?? [],
          cartWidthForMobile: config?.ui_attributes?.cart_overlay_width ?? CART_WIDTH_FOR_MOBILE.FULL
        }
      }
    })
  }

  const parseSSOAttributes = (data: any): SSOMarketingPopup => {
    const ssoConfig = data.find((item: any) => item.sf_context === 'SSO') ?? {}
    if(ssoConfig?.ui_attributes?.popup) {
      return parsePopupFromBackend(ssoConfig?.ui_attributes?.popup as SSOMarketingPopupBackendData)
    }
    return defaultSSOConfig
  }
      
  const merchantData: MerchantAttributesType = {
    accountId: data?.account_id,
    accountType: data?.account_type,
    attributes: {
      isOnboardingComplete: data?.attributes?.is_onboarding_completed ?? true,
      logoUrl: data?.attributes?.logo_url,
      ui: {
        isDiscountsEnabled: data?.attributes?.ui?.is_discounts_enabled ?? true,
        colorPalette: {
          primaryColor:
            data?.attributes?.ui?.color_pallet?.primary_color ??
            data?.attributes?.ui?.color_pallet?.primary_color ??
            '#000000',
          primaryTextColor:
            data?.attributes?.ui?.color_pallet?.primary_text_color ??
            data?.attributes?.ui?.color_pallet?.primary_text_color ??
            '#FFFFFF'
        },
        bannerConfig: parseBannerConfig(data?.attributes?.ui?.banner_config) ?? [],
        couponVisibilityConfig: parseCouponVisibilityConfig(data?.attributes?.ui?.coupon_visibility_config) ?? [],
        primaryCTAConfig: parsePrimaryCTAConfigPayload(data?.attributes?.ui?.primary_cta_config) ?? [],
        logoUrl: data?.attributes?.ui?.logo_url ?? '',
        displayName: data?.attributes?.ui?.display_name ?? 'Shopflo',
        trustBadgeConfig:
          Boolean(data?.attributes?.ui?.trust_badges) && JSON.stringify(data?.attributes?.ui?.trust_badges) !== '{}'
            ? data?.attributes?.ui?.trust_badges
            : defaultTrustBadgesConfig,
        showExitConfirmation: data?.attributes?.ui?.show_exit_confirmation ?? true,
        exitCheckoutAction: data?.attributes?.ui?.exit_checkout_action ?? 'none',
        upSellConfig: parseUpSellConfig(data?.attributes?.ui?.upsell),
        isOrderSummaryOpen: Boolean(data?.attributes?.ui?.layout?.metadata?.is_order_summary_open),
        isQuantityEditable: Boolean(data?.attributes?.ui?.layout?.items_mutable),
        isPriceStrikeThroughEnabled: Boolean(data?.attributes?.metadata?.show_original_price),
        isOnePageCheckoutEnable: Boolean(data?.attributes?.is_one_page_checkout),
        showOrderSummaryAs: data?.attributes?.ui?.layout?.order_summary_style ?? 'TILE'
      },
      name: data?.attributes?.name,
      shopName: data?.attributes?.shop_name,
      codEnabled: data?.attributes?.cod_enabled,
      websiteUrl: data?.attributes?.website_url,
      displayName: data?.attributes?.display_name,
      pincodeEnabled: data?.attributes?.pincode_enabled,
      pincodeUploaded: data?.attributes?.csv_uploaded ?? false,
      prepaidDiscounts: {
        type: data?.attributes?.prepaid_discounts?.type,
        value: data?.attributes?.prepaid_discounts?.value,
        limit: data?.attributes?.prepaid_discounts?.limit,
        enabled: data?.attributes?.prepaid_discounts?.enabled
      },
      addressConfig: {
        isBillingAddressEnabled: data?.attributes?.address_config?.billing_address_enabled,
        isInternationalAddressesEnabled: data?.attributes?.address_config?.international_address_enabled,
        deliveryConfig: {
          isEnabled: data?.attributes?.address_config?.delivery_config?.enabled,
          deliveryText: data?.attributes?.address_config?.delivery_config?.delivery_text,
          isETDEnabled: data?.attributes?.address_config?.delivery_config?.is_etd_enabled ?? false
        },
        codConfig: {
          verifyCod: data?.attributes?.address_config?.cod_config?.verify_cod
        },
        c2pConfig: {
          isEnabled: data?.attributes?.c2p?.enabled,
          activePeriod: data?.attributes?.c2p?.expiry,
          link: {
            enabled: data?.attributes?.c2p?.link?.enabled ?? false,
            expiry: data?.attributes?.c2p?.link?.expiry ?? 0
          },
          widget: {
            enabled: data?.attributes?.c2p?.widget?.enabled ?? true,
            expiry: data?.attributes?.c2p?.widget?.expiry ?? data?.attributes?.c2p?.expiry ?? 300
          }
        }
      },
      codVerification: data?.attributes?.cod_verification,
      ...(data?.attributes?.shopflo_cart && {
        shopfloCart: {
          isEnabled: data?.attributes?.shopflo_cart?.is_enabled,
          designSettings: {
            themeColor: data?.attributes?.shopflo_cart?.design_settings?.theme_color
          },
          announcements: {
            isEnabled: data?.attributes?.shopflo_cart?.announcements?.is_enabled,
            announcementsText: data?.attributes?.shopflo_cart?.announcements?.announcements_text,
            bgColor: data?.attributes?.shopflo_cart?.announcements?.bg_color,
            textColor: data?.attributes?.shopflo_cart?.announcements?.text_color
          },
          functionalitySettings: {
            isCartNotesEnabled: data?.attributes?.shopflo_cart?.functionality_settings?.is_cart_notes_enabled
          },
          discountSettings: {
            isEnabled: data?.attributes?.shopflo_cart?.discount_settings?.is_enabled,
            displayCodes: data?.attributes?.shopflo_cart?.discount_settings?.display_codes
          },
          footerTextSettings: {
            isEnabled: data?.attributes?.shopflo_cart?.footer_text_settings?.is_enabled,
            action: {
              type: data?.attributes?.shopflo_cart?.footer_text_settings?.action?.type,
              properties: {
                text: data?.attributes?.shopflo_cart?.footer_text_settings?.action?.properties?.text,
                navAction: data?.attributes?.shopflo_cart?.footer_text_settings?.action?.properties?.nav_action
              }
            }
          }
        }
      }),
      merchantAgreement: getMerchantAgreementConfig(data?.attributes?.merchant_agreement),
      merchantAgreementStatus: data?.attributes?.merchant_agreement_status,
      announcements: getAnnouncementConfig(data?.attributes?.announcements),
      discountStackLength: data?.attributes?.max_discount_stack_length ?? 2,
      discountSettings: {
        isAutomaticCouponRemovable: data?.attributes?.discounts?.is_automatic_coupon_removable ?? false,
        includeAutomaticInStack: data?.attributes?.discounts?.include_automatic_in_stack ?? false,
        prepaidOffersPreference: data?.attributes?.discounts?.prepaid_offers_preference ?? 'max_first'
      },
      splitCod: data?.attributes?.split_cod
    },
    uiConfig: {
      cartUIConfig: parseCartUIConfig(data?.ui_config) ?? [],
      ssoUIConfig: parseSSOAttributes(data?.ui_config) ?? []
    }
  }

  return merchantData
}

/***********************************************************/
//                Integrations config Parsing logics
/***********************************************************/

export const parseIntegrationsConfig = (data: any) => {
  const facebookEnabledStatus = Boolean(data?.facebook?.enabled || data?.fb_conversion?.enabled)
  const combinedfacebookPixels: FacebookCombinedAnalyticsPixelType[] = []
  data?.facebook?.pixel_id &&
    data?.facebook?.pixel_id?.forEach((analyticsPixel: any, analyticsIndex: number) => {
      let isConversionEnabled = false
      data?.fb_conversion?.pixels &&
        data?.fb_conversion?.pixels?.forEach((conversionPixel: any, conversionIndex: number) => {
          if (analyticsPixel?.id === conversionPixel?.pixel_id) {
            combinedfacebookPixels.push({
              enabled: analyticsPixel?.enabled,
              pixelId: analyticsPixel?.id ?? conversionPixel?.pixel_id,
              conversionsEnabled: conversionPixel?.enabled,
              apiToken: conversionPixel?.access_token,
              channels: analyticsPixel?.available_channels
            })
            isConversionEnabled = true
          }
        })
      if (!isConversionEnabled) {
        combinedfacebookPixels.push({
          enabled: analyticsPixel?.enabled,
          pixelId: analyticsPixel?.id,
          conversionsEnabled: false,
          apiToken: '',
          channels: analyticsPixel?.available_channels
        })
      }
    })
  const combinedFacebookAnalyticsState: FacebookCombinedAnalyticsType = {
    enabled: facebookEnabledStatus,
    pixels: combinedfacebookPixels
  }

  const analyticsConfig: IntegrationsType = {
    google: {
      enabled: data?.google?.enabled ?? false,
      channels: data?.google?.available_channels
    },
    ga: {
      enabled: data?.ga?.enabled ?? false,
      measurementId: data?.ga?.measurement_id ?? '',
      apiSecret: data?.ga?.api_secret ?? '',
      channels: data?.ga?.available_channels
    },
    facebook: combinedFacebookAnalyticsState,
    webengage: {
      enabled: data?.webengage?.enabled ?? false,
      licenseCode: data?.webengage?.license_code ?? '',
      apiKey: data?.webengage?.api_key ?? '',
      isIndiaDatacenter: data?.webengage?.is_india_datacenter ?? false,
      channels: data?.webengage?.available_channels
    },
    googleAds: {
      enabled: data?.google_ad?.enabled ?? false,
      tags: Boolean(data?.google_ad?.tags)
        ? data?.google_ad?.tags?.map((tag: any) => {
            return {
              conversionId: tag?.conversion_id ?? tag?.conversionId,
              conversionLabel: tag?.conversion_label ?? tag?.conversionLabel,
              enhancedConversionEnabled: tag?.enhanced_conversion_enabled ?? tag?.enhancedConversionEnabled,
              channels: tag?.available_channels
            }
          })
        : []
    },
    contlo: {
      apiKey: data?.contlo?.api_key ?? '',
      enabled: data?.contlo?.enabled ?? false,
      channels: data?.contlo?.available_channels
    },
    orderConfirmation: {
      enabled: data?.order_confirmation_scripts?.enabled ?? false,
      script: data?.order_confirmation_scripts?.script ?? ''
    },
    moengage: {
      enabled: data?.moengage?.enabled,
      appId: data?.moengage?.app_id,
      dataCenter: data?.moengage?.data_center,
      dataAPIId: data?.moengage?.data_api_id,
      dataAPIKey: data?.moengage?.data_api_key,
      customerIdType: data?.moengage?.customer_id_type,
      channels: data?.moengage?.available_channels
    },
    clevertap: {
      enabled: data?.clevertap?.enabled,
      accountId: data?.clevertap?.account_id,
      passcode: data?.clevertap?.passcode,
      region: data?.clevertap?.region,
      identity: data?.clevertap?.identity,
      channels: data?.clevertap?.available_channels
    },
    webhooks: {
      enabled: data?.webhooks?.enabled ?? false,
      webhooks:
        data?.webhooks?.webhooks?.map((webhook: any) => {
          return {
            name: webhook?.name ?? '',
            url: webhook?.url ?? '',
            headers: webhook?.headers ?? {},
            eventName: webhook?.event_name ?? '',
            enabled: webhook?.enabled ?? false,
            channels: webhook?.available_channels
          }
        }) ?? []
    },
    limechat: {
      enabled: data?.limechat?.enabled ?? false,
      limechat:
        data?.limechat?.limechat?.map((limechats: any) => {
          return {
            enabled: limechats?.enabled ?? false,
            channels: limechats?.available_channels,
            'x-fb-account-id': limechats?.['x-fb-account-id'],
            'x-limechat-uat': limechats?.['x-limechat-uat']
          }
        }) ?? []
    },
    
    shipRocket: {
      enabled: data?.shipRocket?.enabled ?? false,
      provider: data?.shipRocket?.provider ?? '',
      email: data?.shipRocket?.email ?? '',
      password: data?.shipRocket?.password ?? '',
      pickup_pincode: data?.shipRocket?.pickup_pincode ?? '',
      shipRocketUIFields: data?.shipRocket?.shipRocketUIFields ?? []
    },
    clickPost: {
      enabled: data?.clickPost?.enabled ?? false,
      provider: data?.clickPost?.provider ?? '',
      username: data?.clickPost?.username ?? '',
      key: data?.clickPost?.key ?? '',
      pickup_pincode: data?.clickPost?.pickup_pincode ?? '',
      clickPostUIFields: data?.clickPost?.clickPostUIFields ?? []
    },
    popCoins: {
      loyaltyProvider: data?.popCoins?.loyaltyProvider ?? '',
      metadata: {
        authToken: data?.popCoins?.metadata?.authToken ?? ''
      },
      isActive: data?.popCoins?.isActive ?? false
    }
  }

  return analyticsConfig
}

export const parseGoogleAdsTagsPayload = (tags: GoogleAdsTagsType[]) => {
  const googleAdsTagsPayloadList: any = []
  Boolean(tags)
    ? tags?.forEach((tag: GoogleAdsTagsType) => {
        googleAdsTagsPayloadList.push({
          conversion_id: tag?.conversionId,
          conversion_label: tag?.conversionLabel,
          enhanced_conversion_enabled: tag?.enhancedConversionEnabled,
          channels: getEnabledChannelsArray(tag?.channels)
        })
      })
    : []
  return googleAdsTagsPayloadList
}

export const parseFacebookAnalyticsPixelsPayload = (pixelIds: FacebookCombinedAnalyticsPixelType[]) => {
  const facebookAnalyticsPixelsPayloadList: any = []
  Boolean(pixelIds)
    ? pixelIds?.forEach((pixel: FacebookCombinedAnalyticsPixelType) => {
        facebookAnalyticsPixelsPayloadList.push({
          enabled: pixel?.enabled,
          id: pixel?.pixelId,
          channels: getEnabledChannelsArray(pixel?.channels)
        })
      })
    : []
  return facebookAnalyticsPixelsPayloadList
}

export const parseFacebookConversionPixelsPaylod = (pixels: FacebookCombinedAnalyticsPixelType[]) => {
  const facebookConversionPixelsPayloadList: any = []
  Boolean(pixels)
    ? pixels?.forEach((pixel: FacebookCombinedAnalyticsPixelType) => {
        if (Boolean(pixel?.conversionsEnabled) && Boolean(pixel?.apiToken?.length)) {
          facebookConversionPixelsPayloadList.push({
            enabled: pixel?.conversionsEnabled,
            pixel_id: pixel?.pixelId,
            access_token: pixel?.apiToken,
            channels: getEnabledChannelsArray(pixel?.channels)
          })
        }
      })
    : []
  return facebookConversionPixelsPayloadList
}

export const parseWebhhoksListPayload = (webhooks: WebhookType[]) => {
  const webhooksPayloadList: any = []
  const modifiedWebhooks = [...webhooks]
  webhooks?.forEach((webhook: WebhookType, index: number) => {
    if (webhook?.authorization && webhook?.authorization !== '') {
      const newHeaders = { ...webhook?.headers }
      newHeaders['authorization'] = webhook?.authorization
      const newWebhook: WebhookType = {
        enabled: webhook?.enabled,
        url: webhook?.url,
        eventName: webhook?.eventName,
        headers: newHeaders,
        channels: webhook?.channels
      }
      modifiedWebhooks[index] = newWebhook
    }
  })
  modifiedWebhooks.map((webhook: WebhookType) => {
    webhooksPayloadList.push({
      event_name: webhook?.eventName,
      enabled: webhook?.enabled,
      url: webhook?.url,
      headers: webhook?.headers,
      channels: getEnabledChannelsArray(webhook?.channels)
    })
  })
  return webhooksPayloadList
}
export const parseLimechatPayload = (limechats: LimechatType[]) => {
  const LimechatPayloadList: any = []
  const modifiedLimechat = [...limechats]
  limechats?.forEach((limechat: LimechatType, index: number) => {
    const newLimechat: LimechatType = {
      enabled: limechat?.enabled,
      channels: limechat?.channels,
      'x-fb-account-id': limechat?.['x-fb-account-id'],
      'x-limechat-uat': limechat?.['x-limechat-uat']
    }
    modifiedLimechat[index] = newLimechat
  })
  modifiedLimechat.map((limechat: LimechatType) => {
    LimechatPayloadList.push({
      enabled: limechat?.enabled,
      'x-fb-account-id': limechat?.['x-fb-account-id'],
      'x-limechat-uat': limechat?.['x-limechat-uat'],
      channels: getEnabledChannelsArray(limechat?.channels)
    })
  })
  return LimechatPayloadList
}
/***********************************************************/
//                 Pincode serviceability Parsing logics
/***********************************************************/

export const parseDeliveryGroupConfig = (data: any) => {
  const getDeliveryCharges: LocalDeliveryGroupChargesType[] = data?.delivery_pricing_conditions?.map(
    (item: any, index: number) => {
      return {
        minOrderPrice: item?.minimum_order_price ?? 0,
        maxOrderPrice: item?.maximum_order_price ?? 0,
        deliveryPrice: item?.delivery_price ?? 0,
        codCharges: item?.cod_charges ?? 0,
        isCODAccepted: Boolean(item?.cod_accepted),
        hasAdditionalCODCharges: Boolean(item?.cod_charges),
        hasNoMaxLimit: !Boolean(item?.maximum_order_price)
      }
    }
  )

  return {
    groupID: data?.group_id,
    groupName: data?.name,
    etdText: data?.etd_text,
    isActive: data?.enabled,
    charges: getDeliveryCharges
  }
}

/***********************************************************/
//                 Discount Parsing logics
/***********************************************************/

const getThresholdType = (amount: any, quantity: any, quantity_distribution_data: any, metadata: any) => {
  if (!Boolean(amount || quantity || metadata?.prerequisiteCatalogue?.length)) return 'NONE'
  if (Boolean(amount) && amount > 0) return 'AMOUNT'
  if (Boolean(quantity) && quantity > 0) return 'QUANTITY'
  if (Boolean(quantity_distribution_data?.[0]?.price)) return 'AMOUNT'
  if (Boolean(quantity_distribution_data?.[0]?.quantity)) return 'QUANTITY'
}

const getCatalogueSearchType = (
  product_ids: any,
  collection_ids: any,
  variant_ids: any,
  quantity_distribution_data?: any
) => {
  const hadQuantityContext = Boolean(quantity_distribution_data?.length)
  if (hadQuantityContext) {
    //Here if any of the blocks have at least one collection ids then
    //all the blocks are bound to have collection as CatalogueSearchType
    //otherwise product will be the catalogue search type.
    if (Boolean(quantity_distribution_data?.[0]?.entities?.[0]?.type === 'COLLECTION'))
      return discountConstants.CATALOGUE_COLLECTION
    return discountConstants.CATALOGUE_PRODUCT
  }
  if (!Boolean(product_ids?.length || collection_ids?.length || variant_ids?.length)) return 'ALL'
  if (Boolean(collection_ids?.length)) return discountConstants.CATALOGUE_COLLECTION
  return discountConstants.CATALOGUE_PRODUCT
}

const getCatalogueCatagoryType = (
  product_ids: any,
  collection_ids: any,
  variant_ids: any,
  coupon_type: ApplicationType,
  catalogueNature: CatalogueNatureType
) => {
  if (Boolean(collection_ids?.length)) return 'COLLECTION'
  switch (coupon_type) {
    case 'AMOUNT':
      return discountConstants.CATALOGUE_PRODUCT_AND_VARIANT
    case 'BUNDLE':
      return discountConstants.CATALOGUE_PRODUCT
    case 'BXGY':
      return catalogueNature === 'ENTITLED'
        ? discountConstants.CATALOGUE_VARIANT
        : discountConstants.CATALOGUE_PRODUCT_AND_VARIANT
    case 'PREPAID':
      return discountConstants.CATALOGUE_PRODUCT_AND_VARIANT
  }
}

const getCustomerEligibilityValueType = (
  maxOrderCount: any,
  minOrderCount: any,
  prerequisiteCustomerIds: any,
  isCustomerSpecific: boolean,
  isSegmentSpecific: boolean
) => {
  if (Boolean(isSegmentSpecific)) return 'SEGMENTS'
  if (Boolean(isCustomerSpecific)) return 'SPECIFIC'
  if (isUndefinedOrNullOrNaN(maxOrderCount) && isUndefinedOrNullOrNaN(minOrderCount)) return 'ALL'
  return maxOrderCount === 0 && minOrderCount === 0 ? 'FIRST' : 'CONDITIONAL'
}

const getBulkGenerationMethod = (data: any) => {
  return Boolean(data?.prefix) ||
    Boolean(data?.suffix) ||
    Boolean(data?.random_coupon_length) ||
    Boolean(data?.number_of_coupons_created) ||
    Boolean(data?.allowed_characters)
    ? 'RANDOM_CODES'
    : 'CSV_UPLOAD'
}

const checkAllowedPaymentModeCoverage = (paymentModeConcessions: any, paymentModes: string[]) => {
  const speciifcMode =
    Boolean(Object.keys(paymentModeConcessions)?.filter((key: string) => key !== 'COD')?.length) ||
    Boolean(!paymentModes?.includes('ALL') && paymentModes?.length > 0)
  const allMode =
    !Boolean(paymentModeConcessions?.length === 0 && paymentModes?.length === 0) || paymentModes?.includes('ALL')
  return !allMode || speciifcMode
}

const transformVariantMetadataToProductMetadata = async (metadata: any, accountId: any) => {
  if (typeof metadata === 'undefined') return
  const productMetadataPromises: any[] = await Promise.allSettled(
    metadata?.map(async (variantMetadata: any) => {
      const parentId = variantMetadata?.parentDetails?.id
      if (!Boolean(parentId)) return
      if (!productMetadataPromises?.some(data => data?.id === parentId)) {
        try {
          const catalogueResponse: any = await getProductById(
            process.env.NEXT_PUBLIC_APP_ISSUER_ID ?? '',
            accountId,
            parentId ?? ''
          )

          // When there is an error in fetching the items.
          if (catalogueResponse?.error) {
            console.error('x-trace-id:', catalogueResponse.headers?.['x-trace-id'])
            return
          }
          const productData = catalogueAdapter(catalogueResponse?.data?.data, 'PRODUCT')
          if (productData) {
            productData.variants = [variantMetadata]
          }
          return productData
        } catch (e) {
          console.error(e)
        }
      } else {
        const productIndex = productMetadataPromises?.findIndex(data => data?.id === parentId)
        if (productIndex !== -1) {
          const modifiedVariants = [...productMetadataPromises?.[productIndex]?.variants]
          modifiedVariants?.push(variantMetadata)
          productMetadataPromises[productIndex].variants = modifiedVariants
        }
      }
    })
  )
  const productMetadata = productMetadataPromises?.map(item => {
    if (item?.status === 'fulfilled') {
      return item?.value
    }
  })
  return productMetadata
}

const generateMetadata = async (itemIds: string[], variantIds: string[], accountId: string, catalogueType: string) => {
  if (catalogueType === 'COLLECTION') {
    const collectionResponse = await getItemByIds(
      process.env.NEXT_PUBLIC_APP_ISSUER_ID ?? '',
      accountId,
      itemIds,
      'COLLECTION'
    )
    const collectionMetadataData = collectionResponse?.map((item: any) => catalogueAdapter(item, 'COLLECTION'))
    return collectionMetadataData
  }
  const productResponse = await getItemByIds(process.env.NEXT_PUBLIC_APP_ISSUER_ID ?? '', accountId, itemIds, 'PRODUCT')
  const productMetadata = productResponse?.map((item: any) => catalogueAdapter(item, 'PRODUCT'))
  const variantMetadataData = await getVariantsByIds(process.env.NEXT_PUBLIC_APP_ISSUER_ID ?? '', accountId, variantIds)
  const variantToProductMetadata: any[] = []
  variantMetadataData?.forEach((item: any) => {
    const productIndex = variantToProductMetadata?.findIndex(data => data?.product_id === item?.product?.product_id)
    if (productIndex !== -1) {
      const modifiedVariants = [...variantToProductMetadata?.[productIndex]?.variants]
      modifiedVariants?.push(item)
      variantToProductMetadata[productIndex].variants = modifiedVariants
      return
    }
    variantToProductMetadata.push({ ...item?.product, variants: [item] })
  })
  const finalVariantMetadata = variantToProductMetadata?.map(item => {
    const catalogueData = catalogueAdapter(item, 'PRODUCT')
    if (catalogueData) {
      catalogueData.totalVariantCount = null
    }
    return catalogueData
  })
  return [...productMetadata, ...finalVariantMetadata]
}

const getQuantityDistributionData = async (
  quantityDistributionData: any,
  catalogueNature: CatalogueNatureType,
  metadata: any,
  product_ids?: any,
  collection_ids?: any,
  variant_ids?: any,
  coupon_type?: any,
  accountId?: any,
  minThreshold?: number,
  tags?: string[]
) => {
  if (coupon_type === 'CALLOUT_CARD' && catalogueNature === 'ENTITLED') return
  if (quantityDistributionData?.length) {
    const productMetadataPromises: any[] = await Promise.allSettled(
      quantityDistributionData?.map(async (blockData: any, index: number) => {
        const catalogueCategory = () => {
          if (Boolean(blockData?.entities?.[0]?.type === 'COLLECTION')) return discountConstants.CATALOGUE_COLLECTION
          return discountConstants.CATALOGUE_PRODUCT_AND_VARIANT
        }
        const qtyItemIds: string[] = []
        const qtyVariantIds: string[] = []
        blockData?.entities?.forEach((entry: any, index: number) => {
          entry?.entity_id?.forEach((item: string) => {
            if (entry?.type !== 'VARIANT') {
              qtyItemIds.push(item)
              return
            }
            qtyVariantIds.push(item)
          })
        })
        return {
          quantity: blockData?.quantity,
          maxQuantity: blockData?.max_quantity,
          price: blockData?.price,
          maxPrice: blockData?.max_price,
          maxEnabled: Boolean(blockData?.max_quantity) || Boolean(blockData?.max_price),
          itemsConfig: {
            valueType: catalogueCategory(),
            items: qtyItemIds,
            variants: qtyVariantIds,
            catalogueMetadata:
              quantityDistributionData && (!Boolean(metadata) || !Boolean(Object.keys(metadata)))
                ? await generateMetadata(qtyItemIds, qtyVariantIds, accountId, catalogueCategory())
                : metadata?.[index]
          }
        }
      })
    )
    const parsedQuantityDistributionConfig = productMetadataPromises?.map(item => {
      if (item?.status === 'fulfilled') {
        return item?.value
      }
    })
    return parsedQuantityDistributionConfig
  }
  const transformedMetadata = await transformVariantMetadataToProductMetadata(metadata, accountId)
  return [
    {
      quantity:
        catalogueNature === 'PREREQUISITE' ||
        (catalogueNature === 'ENTITLED' && Boolean(coupon_type === 'BUNDLE' || coupon_type === 'BXGY'))
          ? minThreshold
            ? minThreshold
            : null
          : null,
      itemsConfig: {
        valueType: Boolean(collection_ids?.length)
          ? discountConstants.CATALOGUE_COLLECTION
          : discountConstants.CATALOGUE_PRODUCT_AND_VARIANT,
        items: collection_ids ?? product_ids,
        variants: variant_ids,
        catalogueMetadata:
          getCatalogueCatagoryType(product_ids, collection_ids, variant_ids, coupon_type, catalogueNature) ===
          discountConstants.CATALOGUE_VARIANT
            ? transformedMetadata
            : [metadata]?.[0]
      }
    }
  ]
}

const getQuantityDistributorCheckboxData = (
  quantityDistributionData: any,
  product_ids?: any,
  collection_ids?: any,
  variant_ids?: any
) => {
  if (Boolean(quantityDistributionData?.length)) {
    return Boolean(quantityDistributionData?.[0]?.quantity)
  }
  return false
}

export const getTextAreaContent = (longDescription: string[]) => {
  let tempContent = ''
  longDescription?.forEach((point: string, index: number) => {
    if (index === longDescription?.length - 1) tempContent += '•  ' + point
    else tempContent += '•  ' + point + '\n'
  })
  return tempContent
}

export const getOfferValueAmountType = (data: any) => {
  let hasMaxConcessionAmount = false
  if (Boolean(data?.entitled_entity_quantity_distribution?.length)) return 'FREEBIE'
  if (Boolean(data?.payment_mode_concessions) && Object.keys(data?.payment_mode_concessions)?.length > 0) {
    Object.keys(data?.payment_mode_concessions)?.forEach((key: any) => {
      if (Boolean(data?.payment_mode_concessions?.[key]?.maximum_concession_amount)) {
        hasMaxConcessionAmount = true
      }
    })
    return hasMaxConcessionAmount ? 'PERCENTAGE_CAPPED' : data?.amount_type
  } else {
    return Boolean(data?.maximum_concession_amount) ? 'PERCENTAGE_CAPPED' : data?.amount_type
  }
}

export const parseDiscountConfig = async (
  data: any,
  merchant: MerchantAttributesType | undefined,
  createMode: GenerationType = 'SINGLE'
) => {
  const parsedChannelsList = Boolean(data?.enabled_channels)
    ? await getActiveChannelsFromList(data?.enabled_channels)
    : []

  const parsedEntitledItems = await getQuantityDistributionData(
    data?.entitled_entity_quantity_distribution,
    'ENTITLED',
    Boolean(data?.metadata?.entitledCatalogue?.length)
      ? data?.metadata?.entitledCatalogue
      : data?.metadata?.coveredCatalogue,
    data?.entitled_product_ids,
    data?.entitled_collection_ids,
    data?.entitled_variant_ids,
    data?.coupon_type,
    merchant?.accountId,
    data?.entitled_product_count,
    data?.tags
  )

  const parsedPrerequisiteItems = await getQuantityDistributionData(
    data?.prerequisite_entity_quantity_distribution,
    'PREREQUISITE',
    data?.metadata?.prerequisiteCatalogue,
    data?.prerequisite_product_ids,
    data?.prerequisite_collection_ids,
    data?.prerequisite_variant_ids,
    data?.coupon_type,
    merchant?.accountId,
    data?.prerequisite_count_to_entitlement,
    data?.tags
  )

  const getStackabilityConfig = (): StackabilityConfigType => {
    if (typeof data?.stack_config !== 'undefined') {
      return {
        valueType: data?.stack_config?.stack_type,
        discountIDs: data?.stack_config?.includes
      }
    }
    if (typeof data?.stackable !== 'undefined') {
      return Boolean(data?.stackable) ? { valueType: 'ALL' } : { valueType: 'NONE' }
    }
    return {
      valueType: 'ALL'
    }
  }

  const parsedObject: DiscountFormStateType = {
    discountId: data?.coupon_id,
    setup: {
      applicationMethodConfig: {
        valueType: Boolean(data?.auto_applicable) ? 'AUTOMATIC' : 'MANUAL',
        value: Boolean(data?.auto_applicable || data?.generation_source === 'BULK')
          ? data?.title ?? data?.code ?? ''
          : data?.code ?? data?.title ?? ''
      },
      applicationConfig: {
        valueType: data?.coupon_type,
        discountDescription: {
          discountTitle: data?.title,
          discountDescription: data?.description,
          discountHeader: data?.header,
          discountLongDescription: getTextAreaContent(data?.long_description)
        },
        offerValueConfig: {
          valueType:
            data?.coupon_type === 'PREPAID' || data?.code === 'COD2PREPAID'
              ? getOfferValueAmountType(data)
              : data?.amount_type,
          value: data?.concession_amount ?? 0,
          quantity: data?.entitled_product_count ?? 0,
          cappedValue: data?.maximum_concession_amount ?? 0,
          capCheckboxConfig: {
            valueType: data?.coupon_type === 'PREPAID' ? false : Boolean(data?.maximum_concession_amount)
          },
          isCodAccepted: Boolean(data?.payment_mode_concessions?.COD),
          paymentModeCoverageType: checkAllowedPaymentModeCoverage(
            { ...data?.payment_mode_concessions },
            data?.allowed_payment_modes_for_freebie
          )
            ? 'SPECIFIC'
            : 'ALL',
          paymentModeConcessions:
            getOfferValueAmountType(data) === 'FREEBIE'
              ? parsePaymentModeConcessions(data?.allowed_payment_modes_for_freebie)
              : parsePaymentModeConcessions(data?.payment_mode_concessions),
          isCustomHeader: data?.metadata?.offerValueConfig?.isCustomHeader
            ? data?.metadata?.offerValueConfig?.isCustomHeader
            : 'DEFAULT'
        },
        entitlementsConfig: {
          valueType: getCatalogueSearchType(
            data?.entitled_product_ids,
            data?.entitled_collection_ids,
            data?.entitled_variant_ids,
            data?.entitled_entity_quantity_distribution
          ) as CoverageType,
          setQuantityCheckboxConfig: {
            valueType: getQuantityDistributorCheckboxData(
              data?.entitled_entity_quantity_distribution,
              data?.entitled_product_ids,
              data?.entitled_collection_ids,
              data?.entitled_variant_ids
            )
          },
          quantityDistributorConfig: parsedEntitledItems,
          hasOldProductConstruct:
            Boolean(data?.entitled_product_ids) ||
            Boolean(data?.entitled_collection_ids) ||
            Boolean(data?.entitled_variant_ids)
        },
        discountGenerationConfig: {
          valueType: data?.generation_source,
          bulkGenerationConfig: {
            valueType: getBulkGenerationMethod(data),
            codeCount: data?.number_of_coupons_created ?? 0,
            codeLength: data?.random_coupon_length ?? 0,
            prefix: data?.prefix ?? '',
            suffix: data?.suffix ?? '',
            allowedCharacters: data?.allowed_characters
          }
        },
        maxUsesPerOrder: data?.multiple_application_options?.number_of_times_to_apply ?? 0,
        maxUsesPerOrderCheckboxConfig: {
          valueType: Boolean(data?.multiple_application_options?.enable)
        }
      }
    },
    conditions: {
      thresholdConfig: {
        valueType: getThresholdType(
          data?.prerequisite_subtotal_range,
          data?.prerequisite_count_to_entitlement,
          data?.prerequisite_entity_quantity_distribution,
          data?.metadata
        ),
        minValue: data?.prerequisite_subtotal_range ?? data?.prerequisite_count_to_entitlement ?? 0,
        maxValue: data?.prerequisite_max_order_value ?? data?.prerequisite_max_count_for_entitlement ?? 0,
        maxCheckboxConfig: {
          valueType: Boolean(data?.prerequisite_max_order_value || data?.prerequisite_max_count_for_entitlement)
        }
      },
      prerequisitesConfig: {
        valueType: getCatalogueSearchType(
          data?.prerequisite_product_ids,
          data?.prerequisite_collection_ids,
          data?.prerequisite_variant_ids,
          data?.prerequisite_entity_quantity_distribution
        ) as PrerequisiteType,
        setQuantityCheckboxConfig: { valueType: Boolean(data?.prerequisite_entity_quantity_distribution?.length) },
        quantityDistributorConfig: parsedPrerequisiteItems,
        hasOldProductConstruct:
          Boolean(data?.prerequisite_product_ids) ||
          Boolean(data?.prerequisite_collection_ids) ||
          Boolean(data?.prerequisite_variant_ids)
      }
    },
    customerEligibility: {
      valueType: getCustomerEligibilityValueType(
        data?.prerequisite_user_order_cohort?.lifetime_max_order_count,
        data?.prerequisite_user_order_cohort?.lifetime_min_order_count,
        data?.prerequisite_customer_ids,
        Boolean(data?.customer_specific),
        Boolean(data?.segment_ids?.length)
      ) as CustomerEligibilityType,
      minOrderCount: data?.prerequisite_user_order_cohort?.lifetime_min_order_count ?? 0,
      maxOrderCount: data?.prerequisite_user_order_cohort?.lifetime_max_order_count ?? 0,
      maxOrderCountCheckboxConfig: {
        valueType: Boolean(data?.prerequisite_user_order_cohort?.lifetime_max_order_count)
      },
      segmentIds: data?.segment_ids
    },
    usageLimits: {
      discountUsesConfig: {
        maxUsesCheckboxConfig: {
          valueType: Boolean(data?.max_applicable_count || data?.number_of_times_coupons_to_be_used)
        },
        maxUses: data?.max_applicable_count ?? data?.number_of_times_coupons_to_be_used ?? 0,
        maxUsesPerCustomerCheckboxConfig: { valueType: Boolean(data?.max_applicable_per_user_count) },
        maxUsesPerCustomer: data?.max_applicable_per_user_count
      },
      stackabilityType: getStackabilityConfig(),
      activePeriodConfig: {
        startDate: data?.start_date ?? new Date(),
        endDate: data?.end_date ?? new Date(),
        endDataCheckboxConfig: { valueType: Boolean(data?.end_date) },
        activePeriod: (merchant?.attributes?.addressConfig?.c2pConfig?.activePeriod ?? 0) / 60
      }
    },
    preview: {
      isDiscoverable: data?.discoverable,
      descriptionConfig: {
        descriptionTitle: data?.header,
        description: data?.description,
        longDescription: data?.long_description
      }
    },
    isActive: data?.active,
    tags: data?.tags,
    channels: parsedChannelsList
  }
  return parsedObject
}

export const parseOnboardingData = (data: any) => {
  if (!Boolean(data)) return
  let tax: BillingDetailsPayloadType | undefined
  if (Boolean(data?.tax_details)) {
    tax = {
      poc_phone_number: data?.tax_details?.poc_phone_number ?? '',
      poc_name: data?.tax_details?.poc_name ?? '',
      poc_email_id: data?.tax_details?.poc_email_id ?? '',
      tax_type: data?.tax_details?.tax_type ?? '',
      identification_number: data?.tax_details?.identification_number ?? '',
      directors_name: data?.tax_details?.directors_name ?? '',
      directors_email_id: data?.tax_details?.directors_email_id ?? '',
      business_legal_name: data?.tax_details?.business_legal_name ?? '',
      business_trade_name: data?.tax_details?.business_trade_name ?? '',
      category: data?.tax_details?.category ?? '',
      entity_name: data?.tax_details?.entity_name ?? '',
      entity_type: data?.tax_details?.entity_type ?? '',
      are_addresses_same: data?.tax_details?.are_addresses_same ?? false,
      billing_address: {
        address1: data?.tax_details?.billing_address?.address1 ?? '',
        address2: data?.tax_details?.billing_address?.address2 ?? '',
        zip: data?.tax_details?.billing_address?.zip ?? '',
        city: data?.tax_details?.billing_address?.city ?? '',
        state: data?.tax_details?.billing_address?.state ?? '',
        state_code: data?.tax_details?.billing_address?.state_code ?? '',
        country: data?.tax_details?.billing_address?.country ?? '',
        country_code: data?.tax_details?.billing_address?.country_code ?? ''
      },
      shipping_address: {
        address1: data?.tax_details?.shipping_address?.address1 ?? '',
        address2: data?.tax_details?.shipping_address?.address2 ?? '',
        zip: data?.tax_details?.shipping_address?.zip ?? '',
        city: data?.tax_details?.shipping_address?.city ?? '',
        state: data?.tax_details?.shipping_address?.state ?? '',
        state_code: data?.tax_details?.shipping_address?.state_code ?? '',
        country: data?.tax_details?.shipping_address?.country ?? '',
        country_code: data?.tax_details?.shipping_address?.country_code ?? ''
      }
    }
  }

  const onBoardingData: OnboardingContextDataType = {
    shopName: data?.shop_name ?? '',
    shopURL: data?.shop_domain ?? '',
    dashboardURL: data?.merchant_dashboard_url ?? '',
    onboardingStatus: data?.shop_connection_status ?? null,
    taxDetails: tax,
    gstFileUrl: data?.tax_details?.gst_certificate_file_key ?? ''
  }

  return onBoardingData
}
export const parseChannelDetails = (data: any) => {
  const parsedChannelDetails: ChannelDetailsType = {
    apiKey: data?.api_key,
    dateCreated: data?.created_at,
    description: data?.description,
    s_id: data?.id,
    isTokenRevealed: isContentProtected(data?.api_key),
    name: data?.name
  }
  return parsedChannelDetails
}

export const getEnabledChannelsArray = (channels: ChannelConfigType[] | undefined) => {
  if (!channels) return []
  const enabledChannelsArray: any = []
  try {
    channels.forEach((channel: any) => {
      if (channel.enabled) enabledChannelsArray.push(channel.id)
    })
  } catch (e) {
    console.error(e)
  } finally {
    return enabledChannelsArray
  }
}

export const parseUpSellConfig = async (data: any) => {
  const parsedChannelsList = Boolean(data?.enabled_channels)
    ? await getActiveChannelsFromList(data?.enabled_channels)
    : []
  const formState: UpSellFormStateType = {
    name: data?.name,
    entitlementsConfig: {
      valueType: getCatalogueSearchType(
        data?.entitled_product_ids,
        data?.entitled_collection_ids,
        data?.entitled_variant_ids
      ) as CoverageType,
      entitledItemsConfig: {
        valueType: getCatalogueCatagoryType(
          data?.entitled_product_ids,
          data?.entitled_collection_ids,
          data?.entitled_variant_ids,
          data?.coupon_type,
          'ENTITLED'
        ) as CatalogueCategoryType,
        items: data?.entitled_products?.map((entitled_product: any) => {
          return {
            productId: entitled_product?.product_id,
            productDiscount: {
              type: entitled_product?.discount?.concession_amount === 0 ? 'NONE' : entitled_product?.discount?.type,
              concessionAmount: entitled_product?.discount?.concession_amount
            }
          }
        }),
        catalogueMetadata: data?.metadata?.entitledCatalogue
      }
    },
    prerequisitesConfig: {
      valueType: getCatalogueSearchType(
        data?.prerequisite_product_ids,
        data?.prerequisite_collection_ids,
        data?.prerequisite_variant_ids
      ) as PrerequisiteType,
      prerequisiteItemsConfig: {
        valueType: getCatalogueCatagoryType(
          data?.entitled_product_ids,
          data?.entitled_collection_ids,
          data?.entitled_variant_ids,
          data?.coupon_type,
          'PREREQUISITE'
        ) as CatalogueCategoryType,
        items: data?.prerequisite_collection_ids ?? data?.prerequisite_product_ids ?? [],
        variants: data?.prerequisite_variant_ids ?? [],
        catalogueMetadata: data?.metadata?.prerequisiteCatalogue
      }
    },
    realEstate: data?.real_estate,
    channels: parsedChannelsList
  }
  return formState
}

export const parseSegmentConfig = (data: any): SegmentFormStateType => {
  return {
    name: data?.name,
    description: data?.description,
    sourceType: 'CSV',
    fileLength: data?.metadata?.file_length,
    currentFile: {
      name: data?.file_upload_progress?.file_name,
      url: data?.file_upload_progress?.file_path
    }
  }
}

/***********************************************************/
//                 Rewards analytics parsing logics
/***********************************************************/

export const parseRewardsAnalyticsWidgetsData = (widgetResponse: any) => {
  const targetWidgetConfig: RewardAnalyticsWidgetType | undefined = rewardsAnalyticsWidgetsConfig.find(
    (widgetData: RewardAnalyticsWidgetType) => widgetData?.id === widgetResponse?.widgetId
  )
  if (targetWidgetConfig === undefined) return rewardsAnalyticsWidgetsConfig[0]

  let rewardsAnalyticsWidgetData: RewardAnalyticsWidgetType = targetWidgetConfig

  switch (widgetResponse?.widgetId) {
    case 'reward_earn':
      rewardsAnalyticsWidgetData = {
        id: 'reward_earn',
        title: targetWidgetConfig?.title,
        positionX: targetWidgetConfig?.positionX,
        positionY: targetWidgetConfig?.positionY,
        primaryMetric: {
          value: widgetResponse?.widgetData?.total_credit_issued?.value,
          title: targetWidgetConfig?.primaryMetric?.title !== undefined ? targetWidgetConfig?.primaryMetric?.title : '',
          type:
            targetWidgetConfig?.primaryMetric?.type !== undefined ? targetWidgetConfig?.primaryMetric?.type : 'AMOUNT',
          compareValue: widgetResponse?.widgetData?.total_credit_issued?.compared_value,
          comparePercentage: widgetResponse?.widgetData?.total_credit_issued?.comparision_percentage,
          compareStatus: widgetResponse?.widgetData?.total_credit_issued?.comparision_status
        },
        detailedMetricsList: [
          // [
          //   {
          //     title:
          //       targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.title !== undefined
          //         ? targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.title
          //         : '',
          //     value: widgetResponse?.widgetData?.earn_to_gmv_ratio?.value,
          //     type:
          //       targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.type !== undefined
          //         ? targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.type
          //         : 'PERCENTAGE',
          //     compareValue: widgetResponse?.widgetData?.earn_to_gmv_ratio?.compared_value,
          //     comparePercentage: widgetResponse?.widgetData?.earn_to_gmv_ratio?.comparision_percentage,
          //     compareStatus: widgetResponse?.widgetData?.earn_to_gmv_ratio?.comparision_status
          //   }
          // ],
          [
            {
              title:
                targetWidgetConfig?.detailedMetricsList?.[1]?.[0]?.title !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[1]?.[0]?.title
                  : '',
              value: widgetResponse?.widgetData?.cashback_awarded?.value,
              type:
                targetWidgetConfig?.detailedMetricsList?.[1]?.[0]?.type !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[1]?.[0]?.type
                  : 'AMOUNT',
              compareValue: widgetResponse?.widgetData?.cashback_awarded?.compared_value,
              comparePercentage: widgetResponse?.widgetData?.cashback_awarded?.comparision_percentage,
              compareStatus: widgetResponse?.widgetData?.cashback_awarded?.comparision_status,
              description: widgetResponse?.widgetData?.cashback_awarded?.instances + ' instances'
            },
            {
              title:
                targetWidgetConfig?.detailedMetricsList?.[1]?.[1]?.title !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[1]?.[1]?.title
                  : '',
              value: widgetResponse?.widgetData?.manual_top_up?.value,
              type:
                targetWidgetConfig?.detailedMetricsList?.[1]?.[1]?.type !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[1]?.[1]?.type
                  : 'AMOUNT',
              compareValue: widgetResponse?.widgetData?.manual_top_up?.compared_value,
              comparePercentage: widgetResponse?.widgetData?.manual_top_up?.comparision_percentage,
              compareStatus: widgetResponse?.widgetData?.manual_top_up?.comparision_status,
              description: widgetResponse?.widgetData?.manual_top_up?.instances + ' instances'
            }
          ]
        ]
      }
      break
    case 'rewards_manual_top_up_reasons':
      rewardsAnalyticsWidgetData = {
        id: 'rewards_manual_top_up_reasons',
        title: targetWidgetConfig?.title,
        positionX: targetWidgetConfig?.positionX,
        positionY: targetWidgetConfig?.positionY,
        detailedMetricsList: [
          Object.keys(widgetResponse?.widgetData).map(key => {
            const metricData: AnalyticsMetricType = {
              title: key,
              value: widgetResponse?.widgetData[key]?.value,
              type: 'AMOUNT',
              compareValue: widgetResponse?.widgetData[key]?.compared_value,
              comparePercentage: widgetResponse?.widgetData[key]?.comparision_percentage,
              compareStatus: widgetResponse?.widgetData[key]?.comparision_status
            }
            return metricData
          })
        ]
      }
      break
    case 'reward_burn':
      rewardsAnalyticsWidgetData = {
        id: 'reward_burn',
        title: targetWidgetConfig?.title,
        positionX: targetWidgetConfig?.positionX,
        positionY: targetWidgetConfig?.positionY,
        primaryMetric: {
          value: widgetResponse?.widgetData?.total_credit_burned?.value,
          title: targetWidgetConfig?.primaryMetric?.title !== undefined ? targetWidgetConfig?.primaryMetric?.title : '',
          type:
            targetWidgetConfig?.primaryMetric?.type !== undefined ? targetWidgetConfig?.primaryMetric?.type : 'AMOUNT',
          compareValue: widgetResponse?.widgetData?.total_credit_burned?.compared_value,
          comparePercentage: widgetResponse?.widgetData?.total_credit_burned?.comparision_percentage,
          compareStatus: widgetResponse?.widgetData?.total_credit_burned?.comparision_status
        },
        detailedMetricsList: [
          [
            {
              title:
                targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.title !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.title
                  : '',
              value: widgetResponse?.widgetData?.sales_with_redemption?.value,
              type:
                targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.type !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.type
                  : 'PERCENTAGE',
              compareValue: widgetResponse?.widgetData?.sales_with_redemption?.compared_value,
              comparePercentage: widgetResponse?.widgetData?.sales_with_redemption?.comparision_percentage,
              compareStatus: widgetResponse?.widgetData?.sales_with_redemption?.comparision_status
            }
            // {
            //   title:
            //     targetWidgetConfig?.detailedMetricsList?.[0]?.[1]?.title !== undefined
            //       ? targetWidgetConfig?.detailedMetricsList?.[0]?.[1]?.title
            //       : '',
            //   value: widgetResponse?.widgetData?.burn_to_gmv_ratio?.value,
            //   type:
            //     targetWidgetConfig?.detailedMetricsList?.[0]?.[1]?.type !== undefined
            //       ? targetWidgetConfig?.detailedMetricsList?.[0]?.[1]?.type
            //       : 'PERCENTAGE',
            //   compareValue: widgetResponse?.widgetData?.burn_to_gmv_ratio?.compared_value,
            //   comparePercentage: widgetResponse?.widgetData?.burn_to_gmv_ratio?.comparision_percentage,
            //   compareStatus: widgetResponse?.widgetData?.burn_to_gmv_ratio?.comparision_status
            // }
          ],
          [
            {
              title:
                targetWidgetConfig?.detailedMetricsList?.[1]?.[0]?.title !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[1]?.[0]?.title
                  : '',
              value: widgetResponse?.widgetData?.cashback_burn?.value,
              type:
                targetWidgetConfig?.detailedMetricsList?.[1]?.[0]?.type !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[1]?.[0]?.type
                  : 'PERCENTAGE',
              compareValue: widgetResponse?.widgetData?.cashback_burn?.compared_value,
              comparePercentage: widgetResponse?.widgetData?.cashback_burn?.comparision_percentage,
              compareStatus: widgetResponse?.widgetData?.cashback_burn?.comparision_status,
              description: widgetResponse?.widgetData?.cashback_burn?.instances + ' times'
            },
            {
              title:
                targetWidgetConfig?.detailedMetricsList?.[1]?.[1]?.title !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[1]?.[1]?.title
                  : '',
              value: widgetResponse?.widgetData?.manual_burn?.value,
              type:
                targetWidgetConfig?.detailedMetricsList?.[1]?.[1]?.type !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[1]?.[1]?.type
                  : 'PERCENTAGE',
              compareValue: widgetResponse?.widgetData?.manual_burn?.compared_value,
              comparePercentage: widgetResponse?.widgetData?.manual_burn?.comparision_percentage,
              compareStatus: widgetResponse?.widgetData?.manual_burn?.comparision_status,
              description: 'X ' + widgetResponse?.widgetData?.manual_burn?.instances
            }
          ]
        ]
      }
      break
    case 'rewards_expiry':
      rewardsAnalyticsWidgetData = {
        id: 'rewards_expiry',
        title: targetWidgetConfig?.title,
        positionX: targetWidgetConfig?.positionX,
        positionY: targetWidgetConfig?.positionY,
        primaryMetric: {
          value: widgetResponse?.widgetData?.rewards_expiry?.value,
          title: targetWidgetConfig?.primaryMetric?.title !== undefined ? targetWidgetConfig?.primaryMetric?.title : '',
          type:
            targetWidgetConfig?.primaryMetric?.type !== undefined ? targetWidgetConfig?.primaryMetric?.type : 'AMOUNT',
          compareValue: widgetResponse?.widgetData?.rewards_expiry?.compared_value,
          comparePercentage: widgetResponse?.widgetData?.rewards_expiry?.comparision_percentage,
          compareStatus: widgetResponse?.widgetData?.rewards_expiry?.comparision_status
        }
      }
      break
    case 'rewards_customers':
      rewardsAnalyticsWidgetData = {
        id: 'rewards_customers',
        title: targetWidgetConfig?.title,
        positionX: targetWidgetConfig?.positionX,
        positionY: targetWidgetConfig?.positionY,
        secondaryMetricsList: [
          {
            value: widgetResponse?.widgetData?.earned_credits?.instances,
            title:
              targetWidgetConfig?.secondaryMetricsList?.[0]?.title !== undefined
                ? targetWidgetConfig?.secondaryMetricsList?.[0]?.title
                : '',
            type:
              targetWidgetConfig?.secondaryMetricsList?.[0]?.type !== undefined
                ? targetWidgetConfig?.secondaryMetricsList?.[0]?.type
                : 'NUMBER',
            compareValue: widgetResponse?.widgetData?.earned_credits?.compared_value,
            comparePercentage: widgetResponse?.widgetData?.earned_credits?.comparision_percentage,
            compareStatus: widgetResponse?.widgetData?.earned_credits?.comparision_status
          },
          {
            value: widgetResponse?.widgetData?.burned_credits?.instances,
            title:
              targetWidgetConfig?.secondaryMetricsList?.[1]?.title !== undefined
                ? targetWidgetConfig?.secondaryMetricsList?.[1]?.title
                : '',
            type:
              targetWidgetConfig?.secondaryMetricsList?.[1]?.type !== undefined
                ? targetWidgetConfig?.secondaryMetricsList?.[1]?.type
                : 'NUMBER',
            compareValue: widgetResponse?.widgetData?.burned_credits?.compared_value,
            comparePercentage: widgetResponse?.widgetData?.burned_credits?.comparision_percentage,
            compareStatus: widgetResponse?.widgetData?.burned_credits?.comparision_status
          },
          {
            value: widgetResponse?.widgetData?.repeat_frequency?.value,
            title:
              targetWidgetConfig?.secondaryMetricsList?.[2]?.title !== undefined
                ? targetWidgetConfig?.secondaryMetricsList?.[2]?.title
                : '',
            type:
              targetWidgetConfig?.secondaryMetricsList?.[2]?.type !== undefined
                ? targetWidgetConfig?.secondaryMetricsList?.[2]?.type
                : 'TIME',
            compareValue: widgetResponse?.widgetData?.repeat_frequency?.compared_value,
            comparePercentage: widgetResponse?.widgetData?.repeat_frequency?.comparision_percentage,
            compareStatus: widgetResponse?.widgetData?.repeat_frequency?.comparision_status
          }
        ],
        detailedMetricsList: [
          [
            {
              title:
                targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.title !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.title
                  : '',
              value: widgetResponse?.widgetData?.no_burn?.value,
              type:
                targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.type !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[0]?.[0]?.type
                  : 'AMOUNT',
              compareValue: widgetResponse?.widgetData?.no_burn?.compared_value,
              comparePercentage: widgetResponse?.widgetData?.no_burn?.comparision_percentage,
              compareStatus: widgetResponse?.widgetData?.no_burn?.comparision_status,
              description: widgetResponse?.widgetData?.no_burn?.instances + ' instances'
            },
            {
              title:
                targetWidgetConfig?.detailedMetricsList?.[0]?.[1]?.title !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[0]?.[1]?.title
                  : '',
              value: widgetResponse?.widgetData?.two_burn?.value,
              type:
                targetWidgetConfig?.detailedMetricsList?.[0]?.[1]?.type !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[0]?.[1]?.type
                  : 'AMOUNT',
              compareValue: widgetResponse?.widgetData?.two_burn?.compared_value,
              comparePercentage: widgetResponse?.widgetData?.two_burn?.comparision_percentage,
              compareStatus: widgetResponse?.widgetData?.two_burn?.comparision_status,
              description: widgetResponse?.widgetData?.two_burn?.instances + ' instances'
            },
            {
              title:
                targetWidgetConfig?.detailedMetricsList?.[0]?.[2]?.title !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[0]?.[2]?.title
                  : '',
              value: widgetResponse?.widgetData?.more_than_two_burn?.value,
              type:
                targetWidgetConfig?.detailedMetricsList?.[0]?.[2]?.type !== undefined
                  ? targetWidgetConfig?.detailedMetricsList?.[0]?.[2]?.type
                  : 'AMOUNT',
              compareValue: widgetResponse?.widgetData?.more_than_two_burn?.compared_value,
              comparePercentage: widgetResponse?.widgetData?.more_than_two_burn?.comparision_percentage,
              compareStatus: widgetResponse?.widgetData?.more_than_two_burn?.comparision_status,
              description: widgetResponse?.widgetData?.more_than_two_burn?.instances + ' instances'
            }
          ]
        ]
      }
      break
  }
  return rewardsAnalyticsWidgetData
}

export const parseMandatoryOTP = (data: any) => {
  return Boolean(data?.data?.configuration?.force_verification)
}
