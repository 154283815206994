// ** React Import
import { ReactNode, useState } from 'react'

// ** MUI Import
import List from '@mui/material/List'
import Box from '@mui/material/Box'

// ** Type Import
import { Settings } from 'src/@core/context/settingsContext'
import { VerticalNavItemsType } from 'src/@core/layouts/types'

// ** Component Imports
import Drawer from './Drawer'
import VerticalNavItems from './VerticalNavItems'
import VerticalNavHeader from './VerticalNavHeader'

interface Props {
  hidden: boolean
  navWidth: number
  settings: Settings
  children: ReactNode
  navVisible: boolean
  toggleNavVisibility: () => void
  setNavVisible: (value: boolean) => void
  verticalNavItems?: VerticalNavItemsType
  saveSettings: (values: Settings) => void
  verticalNavMenuContent?: (props?: any) => ReactNode
  afterVerticalNavMenuContent?: (props?: any) => ReactNode
  beforeVerticalNavMenuContent?: (props?: any) => ReactNode
  className?: string
}

const Navigation = (props: Props) => {
  // ** Props
  const { hidden, verticalNavMenuContent: userVerticalNavMenuContent } = props

  // ** States
  const [groupActive, setGroupActive] = useState<string[]>([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState<string[]>([])

  const navigationWidth = hidden ? 0 : 260

  return (
    <Box
      sx={{
        position: 'sticky',
        top: 0,
        left: 0,
        height: '100vh',
        background: '#FAFAFA',
        borderRight: hidden ? '0px' : '2px solid #F0F0F0',
        overflowY: 'auto',
        overflowX: 'hidden',
        minWidth: `${navigationWidth}px`,
        '&::-webkit-scrollbar': {
          width: '6px',
          color: '#E8E8E8'
        },
        '&::-webkit-scrollbar-track': {
          background: '#E8E8E8',
          marginTop: '60px'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#BDBDBD',
          borderRadius: '3px'
        },
        '&::-webkit-scrollbar-thumb:hover': {
          background: '#9E9E9E'
        }
      }}
    >
      <Drawer {...props}>
        <VerticalNavHeader {...props} />
        <Box
          sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            background: '#FAFAFA',
          }}
        >
          {userVerticalNavMenuContent ? (
            userVerticalNavMenuContent(props)
          ) : (
            <List className='nav-items' sx={{ transition: 'padding .25s ease', padding: '0.5rem' }}>
              <VerticalNavItems
                groupActive={groupActive}
                setGroupActive={setGroupActive}
                currentActiveGroup={currentActiveGroup}
                setCurrentActiveGroup={setCurrentActiveGroup}
                {...props}
              />
            </List>
          )}
        </Box>
      </Drawer>
    </Box>
  )
}

export default Navigation
