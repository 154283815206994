//General constants
export const constants = {
  AUTH_COOKIE_CLIENT: 'merchant_dash_auth',
  REFRESH_TOKEN_COOKIE_CLIENT: 'merchant_dash_refresh',
  AUTH_TOKEN_HEADER_SERVER: 'Authorization',
  AUTH_COOKIE_CLIENT_EXPIRY: 1 / 24,
  REFRESH_TOKEN_COOKIE_CLIENT_EXPIRY: 365,
  FLO_USER_COOKIE_EXPIRY: 1000,
  ENV_PROD: 'production',
  ENV_DEV: 'development',
  FLO_FILTERS: 'flo_filters',
  FLO_USER_DATE: 'flo_user_data',
  FLO_MERCHANT_DATA: 'flo_merchant_data',
  FLO_SHIPPING_NOTIFICATION_COOKIE: 'flo_shipping_notification',
  FLO_SHOPFLO_VERSION: 'X-Shopflo-Version',
  FLO_SHOW_ANNOUNCEMENT: 'flo_show_announcement'
}

//API Uri's constants
export const apiURI = {
  FLO_CHECKOUT: process.env.NEXT_PUBLIC_APP_API_URI,
  FLO_KRATOS_CHECKOUT: process.env.NEXT_PUBLIC_APP_KRATOS_API_URI,
  PUBLIC_FLO_CHECKOUT: process.env.NEXT_PUBLIC_APP_API_PUBLIC_URI,
  CHECKOUT_NODE: process.env.NEXT_PUBLIC_APP_ANALYTICS_API_URI,
  ACTIVITY_LOG_SEND: process.env.NEXT_PUBLIC_APP_ANALYTICS_ACTIVITY_LOG_SEND_API_URI,
  ACTIVITY_LOG_GET: process.env.NEXT_PUBLIC_APP_ANALYTICS_ACTIVITY_LOG_GET_API_URI,
  DISCOUNT_ENGINE: process.env.NEXT_PUBLIC_APP_DE_API_URI,
  CATALOGUE_ENGINE: process.env.NEXT_PUBLIC_APP_CE_API_URI,
  PINCODE_ENGINE: process.env.NEXT_PUBLIC_APP_PE_API_URI,
  AUTH_ENGINE: process.env.NEXT_PUBLIC_APP_AE_API_URI,
  REWARDS_ENGINE: process.env.NEXT_PUBLIC_APP_RE_API_URI,
  SHIPPING_ENGINE: process.env.NEXT_PUBLIC_APP_SE_API_URI,
  SHOPIFY_CONNECTOR: process.env.NEXT_PUBLIC_APP_SHOPIFY_CONNECTOR_API_URI,
  FMS_ENGINE: process.env.NEXT_PUBLIC_APP_FMS_API_URI,
  SETTLEMENT_ENGINE: process.env.NEXT_PUBLIC_APP_FE_API_URI,
  SEGMENTS_ENGINE: process.env.NEXT_PUBLIC_APP_SHORTS_API_URI
}

//Dashboard tabs constants
export const dashboardTabs = {
  FLO_ORDERS_TAB: 'orders',
  FLO_ANALYTICS_TAB: 'analytics',
  FLO_CHECKOUT_TAB: 'abandonedCarts',
  FLO_REWARDS_ANALYTICS_TAB: 'rewards',
  FLO_UPSELL_ANALYTICS_TAB: 'upsell',
  FLO_CART_ANALYTICS_TAB: 'cart_analytics',
  FLO_INSIGHTS_ANALYTICS_TAB: 'insights',
  FLO_SSO_ANALYTICS_TAB: 'sso_analytics'
}

//Order's table constants
export const orderTableConstants = {
  PAYMENT_MODE_PREPAID: 'PREPAID',
  PAYMENT_MODE_COD: 'COD',
  PAYMENT_MODE_SPLIT_COD: 'SPLIT COD',
  REFUND_STATUS_REFUNDED: 'Refunded',
  REFUND_ACTION_ISSUE: 'Issue',
  REFUND_STATUS_NOT_APPICABLE: 'Not Applicable',
  CANCELLATION_STATUS_CANCELLED: 'Cancelled',
  CANCELLATION_ACTION_CANCEL: 'Cancel',
  NOT_APPLICABLE: 'N.A.',
  DASH: '-',
  ZERO: '0'
}

//Analytic's constants
export const analyticsConstants = {
  FLO_ORDERS: 'shopflo_orders',
  FLO_ORDERS_SALES: 'shopflo_orders_sales',
  FLO_ORDERS_COUNT: 'shopflo_orders_count',
  FLO_CONVERSION_FUNNEL: 'conversion_funnel',
  FLO_SALES_WIDGET: 'utm_sales_widget',
  FLO_COD_TO_PREPAID: 'cod_to_prepaid',
  FLO_SUCCESS_RATE: 'payment_success_rate',
  FLO_CONVERSION_CHART: 'conversion_chart',
  FLO_PAYMENT_SPLIT: 'payment_split',
  FLO_CONVERSION_REPORT: 'conversion_over_time_table',
  FLO_UTM_FIRST_CLICK_SALES_WIDGET: 'utm_first_click',
  FLO_PREPAID_SHARE: 'prepaid_share',
  FLO_DISCOUNT_PERFORMANCE: 'discount_performance',
  FLO_CONVERSIONS_WITH_DISCOUNTS: 'conversion_with_discounts',
  FLO_CONVERSIONS_WITHOUT_DISCOUNTS: 'conversion_without_discounts',
  FLO_DISCOUNT_CONVERSIONS: 'discount_conversions',
  FLO_DISCOUNT_FAILURE_RATES: 'failed_discount_app',
  FLO_PAYMENT_SPLIT_REPORT: 'payment_split_with_timescale',
  FLO_PREFILLED_ADDRESS_WIDGET: 'prefilled_address',
  FLO_RECOVERED_CHECKOUTS: 'recovered_checkouts',
  FLO_ACR_SURVEY_DATA: 'acr_survey_data',
  FLO_ACR_SURVEY_DATA_ALL: 'acr_survey_data_all',

  FLO_CONVERSION_UNIT_COUNT: 'count',
  FLO_CONVERSION_UNIT_PERCENTAGE: 'percentage',
//cart conversion constants
  FLO_CART_CONVERSION_PERCENTAGE: 'flo_cart_conversion_percentage',
  FLO_CART_CLICKED_COUNT: 'flo_chart_clicked_count',
  FLO_CHECKOUT_INITIATED_COUNT: 'flo_checkout_inited_count',
}

//Discount table constants
export const discountTableConstants = {
  DISCOUNT_TAG_SHOPFLO: 'SHOPFLO',
  DISCOUNT_TAG_IMPORTED: 'IMPORTED'
}

export const discountConstants = {
  CATALOGUE_COLLECTION: 'COLLECTION',
  CATALOGUE_PRODUCT: 'PRODUCT',
  CATALOGUE_VARIANT: 'VARIANT',
  CATALOGUE_PRODUCT_AND_VARIANT: 'PRODUCT_AND_VARIANT'
}

export const alertSeverites = {
  SEVERITY_SUCCESS: 'success',
  SEVERITY_ERROR: 'error',
  SEVERITY_WARNING: 'warning',
  SEVERITY_INFO: 'info'
}

export const permissionType = {
  TAB_OVERVIEW: 'TAB_OVERVIEW',
  VIEW_OVERVIEW: 'VIEW_OVERVIEW',
  DOWNLOAD_REPORT_OVERVIEW: 'DOWNLOAD_REPORT_OVERVIEW',
  TAB_ORDERS: 'TAB_ORDERS',
  VIEW_ORDERS: 'VIEW_ORDERS',
  CANCEL_ORDERS: 'CANCEL_ORDERS',
  REFUND_ORDERS: 'REFUND_ORDERS',
  EDIT_ORDERS: 'EDIT_ORDERS',
  EXPORT_ORDER_DATA: 'EXPORT_ORDER_DATA',
  TAB_ABANDONED_CHECKOUTS: 'TAB_ABANDONED_CHECKOUTS',
  VIEW_ABC_TAB: 'VIEW_ABC_TAB',
  EXIT_FORM_CONFIG: 'EXIT_FORM_CONFIG',
  EXPORT_ABC_DATA: 'EXPORT_ABC_DATA',
  PLACE_BY_ACR: 'PLACE_BY_ACR',
  TAB_UPSELL: 'TAB_UPSELL',
  VIEW_UPSELL: 'VIEW_UPSELL',
  CREATE_UPSELL: 'CREATE_UPSELL',
  ENABLE_UPSELL: 'ENABLE_UPSELL',
  DELETE_UPSELL: 'DELETE_UPSELL',
  VIEW_UPSELL_ANALYTICS: 'VIEW_UPSELL_ANALYTICS',
  TAB_DISCOUNTS: 'TAB_DISCOUNTS',
  VIEW_DISCOUNTS: 'VIEW_DISCOUNTS',
  CREATE_DISCOUNTS: 'CREATE_DISCOUNTS',
  ACTIVATE_DISCOUNTS: 'ACTIVATE_DISCOUNTS',
  DELETE_DISCOUNTS: 'DELETE_DISCOUNTS',
  TAB_AUTOMATIONS: 'TAB_AUTOMATIONS',
  VIEW_AUTOMATIONS: 'VIEW_AUTOMATIONS',
  CREATE_AUTOMATIONS: 'CREATE_AUTOMATIONS',
  ACTIVATE_AUTOMATIONS: 'ACTIVATE_AUTOMATIONS',
  DELETE_AUTOMATIONS: 'DELETE_AUTOMATIONS',
  TAB_PAYMENT_OFFERS: 'TAB_PAYMENT_OFFERS',
  VIEW_PAYMENT_OFFERS: 'VIEW_PAYMENT_OFFERS',
  CREATE_PAYMENT_OFFERS: 'CREATE_PAYMENT_OFFERS',
  ACTIVATE_PAYMENT_OFFERS: 'ACTIVATE_PAYMENT_OFFERS',
  DELETE_PAYMENT_OFFERS: 'DELETE_PAYMENT_OFFERS',
  TAB_REWARDS: 'TAB_REWARDS',
  EDIT_REWARDS_SETUP: 'EDIT_REWARDS_SETUP',
  ENABLE_REWARDS: 'ENABLE_REWARDS',
  VIEW_REWARDS_SETUP: 'VIEW_REWARDS_SETUP',
  VIEW_REWARDS_CUSTOMISATION: 'VIEW_REWARDS_CUSTOMISATION',
  EDIT_REWARDS_CUSTOMISATION: 'EDIT_REWARDS_CUSTOMISATION',
  VIEW_REWARDS_ANALYTICS: 'VIEW_REWARDS_ANALYTICS',
  TAB_SHIPPING_DELIVERY_V2: 'TAB_SHIPPING_DELIVERY_V2',
  VIEW_SHIPPING_DELIVERY: 'VIEW_SHIPPING_DELIVERY',
  EDIT_SHIPPING_DELIVERY: 'EDIT_SHIPPING_DELIVERY',
  MANAGE_RATES_VIEW: 'MANAGE_RATES_VIEW',
  MANAGE_RATES_EDIT: 'MANAGE_RATES_EDIT',
  TAB_RTO_SUITE: 'TAB_RTO_SUITE',
  VIEW_RTO_SUITE: 'VIEW_RTO_SUITE',
  EDIT_RTO_SUITE: 'EDIT_RTO_SUITE',
  TAB_CUSTOMISE_UI: 'TAB_CUSTOMISE_UI',
  VIEW_CUSTOMISE_UI: 'VIEW_CUSTOMISE_UI',
  EDIT_CUSTOMISE_UI: 'EDIT_CUSTOMISE_UI',
  CUSTOMIZE_BANNER_PAYMENT: 'CUSTOMIZE_BANNER_PAYMENT',
  CUSTOMIZE_PAYMENT_METHODS: 'CUSTOMIZE_PAYMENT_METHODS',
  CUSTOMISE_COUPONS_ADDRESS: 'CUSTOMISE_COUPONS_ADDRESS',
  CUSTOMISE_COUPONS_LOGIN: 'CUSTOMISE_COUPONS_LOGIN',
  CUSTOMIZE_BANNER_ADDRESS: 'CUSTOMIZE_BANNER_ADDRESS',
  CUSTOMIZE_BANNER_LOGIN: 'CUSTOMIZE_BANNER_LOGIN',
  CUSTOMISE_COUPONS_PAYMENT: 'CUSTOMISE_COUPONS_PAYMENT',
  TRUST_BADGES: 'TRUST_BADGES',
  CHANGE_DESCRIPTION: 'CHANGE_DESCRIPTION',
  TAB_CART: 'TAB_CART',
  VIEW_CART: 'VIEW_CART',
  EDIT_CART: 'EDIT_CART',
  TAB_SETTINGS: 'TAB_SETTINGS',
  VIEW_SETTINGS: 'VIEW_SETTINGS',
  EDIT_SETUP_SETTINGS: 'EDIT_SETUP_SETTINGS',
  EDIT_SHOP_SETTINGS: 'EDIT_SHOP_SETTINGS',
  EDIT_ACCOUNTS_SETTINGS: 'EDIT_ACCOUNTS_SETTINGS',
  MULTIPLE_FB_CAPI: 'MULTIPLE_FB_CAPI',
  MANAGE_INVOICE: 'MANAGE_INVOICE',
  AUTO_PG_ROUTING: 'AUTO_PG_ROUTING',
  ADD_MEMBER: 'ADD_MEMBER',
  ADD_CUSTOM_ROLE: 'ADD_CUSTOM_ROLE',
  MULTI_PG_SUPPORT: 'MULTI_PG_SUPPORT',
  CHANNEL: 'CHANNEL',
  ACR_EVENT_WEBHOOK: 'ACR_EVENT_WEBHOOK',
  TAB_SUPPORT: 'TAB_SUPPORT',
  TAB_PARTNERS: 'TAB_PARTNERS',
  BULK_CREATE_DISCOUNTS: 'BULK_CREATE_DISCOUNTS',
  IMPORT_DISCOUNT: 'IMPORT_DISCOUNT',
  BUNDLE_DISCOUNTS: 'BUNDLE_DISCOUNTS',
  GROUP_DISCOUNTS: 'GROUP_DISCOUNTS',
  DISCOUNT_EDIT_DESCRIPTION: 'DISCOUNT_EDIT_DESCRIPTION',
  BANNER_CTA_TEXT: 'BANNER_CTA_TEXT',
  PAYMENT_TITLE_REORDER: 'PAYMENT_TITLE_REORDER',
  CUSTOMIZE_CHECKOUT_UI: 'CUSTOMIZE_CHECKOUT_UI',
  CUSTOMIZE_COUPONS: 'CUSTOMIZE_COUPONS',
  COUPON_ORDERING: 'COUPON_ORDERING',
  COUPON_TEXT_CUSTOMISE: 'COUPON_TEXT_CUSTOMISE',
  PAYMENT_SETTINGS: 'PAYMENT_SETTINGS',
  PG_SUPPORT: 'PG_SUPPORT',
  PAYMENT_TILE_REORDERING: 'PAYMENT_TILE_REORDERING',
  RATE_LEVEL_ETD_COD: 'RATE_LEVEL_ETD_COD',
  SF_DELIVERY_GROUPS: 'SF_DELIVERY_GROUPS',
  ACTIVATE_REWARDS: 'ACTIVATE_REWARDS',
  DISCOUNT_PERFORMANCE: 'DISCOUNT_PERFORMANCE',
  ITEM_OFFER_DISCOUNTS: 'ITEM_OFFER_DISCOUNTS',
  CHANGE_COUPON_DESCRIPTION: 'CHANGE_COUPON_DESCRIPTION',
  ZONE_LEVEL_COD: 'ZONE_LEVEL_COD',
  ZONE_LEVEL_ETD: 'ZONE_LEVEL_ETD',
  CONFIGURE_ZONE_RATE: 'CONFIGURE_ZONE_RATE',
  CUSTOMIZE_COUPONS_ADDRESS: 'CUSTOMISE_COUPONS_ADDRESS',
  CUSTOMIZE_COUPONS_LOGIN: 'CUSTOMISE_COUPONS_LOGIN',
  CUSTOMIZE_COUPONS_PAYMENT: 'CUSTOMISE_COUPONS_PAYMENT',
  COUPON_REORDERING: 'COUPON_REORDERING',
  DISCOUNT_AI: 'DISCOUNT_AI',
  TAB_INVOICES: 'TAB_INVOICES',
  SPLIT_COD: 'SPLIT_COD'
}

export const permissionsConfig = []

export enum analyticsApiRequestType {
  GET = 'GET',
  POST = 'POST'
}
